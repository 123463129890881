<template>
  <LiefengContent>
    <template #title>数据展示</template>
    <template v-slot:toolsbarLeft>
        <DatePicker 
            :options="options"
            @on-change='changeTime'
            type="daterange" 
            show-week-numbers placement="bottom-end" 
            placeholder="选择日期" 
            class="picker"
            v-model="timeData"
            :clearable="false"
            :disabled='disabled'
        ></DatePicker>
    </template>
    <template v-slot:toolsbarRight>
        <Timedropdown :disabled='disabled' @changeTime='getTime' ></Timedropdown>
        <Button @click="exporta "  type="error">导出</Button>
        <Button @click="$router.push('/appletsurvey')">概况</Button>
        <Button @click="$router.push('/accesstrends')">访问趋势</Button>
        <Button @click="$router.push('/accessspread')" >访问分布</Button>
        <Button @click="$router.push('/savedata')" type="primary">数据展示</Button>
        <!-- <Button @click="$router.push('/userportrait')">用户画像</Button> -->
    </template>
    <template #contentArea>
      <LiefengTable 
        ref='lftableo'
        :talbeColumns="columns" 
        :tableData="adata" 
        :loading='loading' 
        :fixTable="true"
        :hidePage='true'
      ></LiefengTable>
      
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "./children/LiefengContent";
import Timedropdown from './children/timedropdown';
import LiefengTable from "./children/LiefengTable";
export default {
  data() {
    return {
      disabled:false,
      options: {
        disabledDate(date) {
          return date && date.valueOf() > new Date() - 1000 * 60 * 60 * 24 * 1;
        }
      },
      loading:false,
      timeData:[this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*7), "yyyy-MM-dd"),this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd")],

      columns:[
        {
          minWidth:150,
          title:'日期',
          key:'statDate',
          align:"center",
          // render: (h, params) => {
          //   return h("div", {},params.row.statDate.replace(/-/g,"."))
          // }
        },
        {
          minWidth:150,
          title:'新用户数',
          key:'newUv',
          align:"center"
        },
        {
          minWidth:150,
          title:'累计用户数',
          key:'totalUser',
          align:"center"
        },
        {
          minWidth:150,
          title:'累计访问次数',
          key:'totalPv',
          align:"center"
        },
        {
          minWidth:150,
          title:'新访问人数',
          key:'newUv',
          align:"center"
        },
        {
          minWidth:150,
          title:'累计访问人数',
          key:'totalUv',
          align:"center"
        },
        {
          minWidth:150,
          title:'转发次数',
          key:'shareNum',
          align:"center"
        },
        {
          minWidth:150,
          title:'转发人数',
          key:'shareUser',
          align:"center"
        },
        {
          title:'人均停留时长(s)',
          key:'stayUser',
          align:"center",
          minWidth:150
        },
        {
          title:'次均停留时长(s)',
          key:'stayPv',
          align:"center",
          minWidth:150
        },
        
      ],
      adata:[
      ]
    };
  },
  created(){
    this.getData()
  },
  methods: {
    exporta(){
      this.$refs.lftableo.exporta()
    //   this.$Modal.confirm({
    //     title: "温馨提示",
    //     content:"是否当前数据</p>",
    //     onOk: () => { 
    //     this.$refs.lftable.exportCsv({
    //     filename: '小程序数据'
    //   })}})
    },
    getTime(val){
      this.timeData = val
      this.getData()
    },
    changeTime(val){
      this.timeData[0] = val[0]
      this.timeData[1] = val[1]
      this.getData()
    },
    getData(){
      this.loading = true
      this.disabled = true
      this.$get('/statistic/api/symanage/pc/weappStat/getAllData',
        {
          startDate:this.timeData[0],
          endDate:this.timeData[1]
        }).then( res => {
          this.disabled = false
          if(res.code == 200 && res.data){
            this.loading = false
            let arr = JSON.parse(JSON.stringify(res.data.weappDailyStatTotalVoList))
            arr.forEach( item => {
              item.statDate = item.statDate.replace(/-/g,'.')
            })
            console.log(arr)
            this.adata = arr
          }else{
            this.$Message.error({
              content:'获取数据失败',
              background:true
            })
          }
        })
    }
  },
  components: {
    LiefengContent,
    Timedropdown,
    LiefengTable
  },
};
</script>
    
<style scoped lang='less'>
.picker{
    position: absolute;
    right: 0;
}
</style>